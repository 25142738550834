import { fields } from "./fields";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export const filtersElements = [
  {
    value: fields.FILTER_PERIOD,
    dateValues: [fields.FILTER_PERIOD_FROM, fields.FILTER_PERIOD_TO],
    title: "Период",
    tooltip: "Выбор определённого хронологического периода времени с помощью календаря",
    type: "datePeriod",
    width: 250,
    educationId: educationIds.listViewSettings,
  },
  {
    value: fields.FILTER_ARCHIVE,
    title: "Архив",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "autocomplete",
    showSelectedKey: "name",
    autocompleteActionName: "getArchiveAutocompleteByCode",
    className: "mt-4 archive-filter",
    width: 250,
  },
];
