import { v4 as uuid } from "uuid";
// import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { fields } from "./fields";

export const tableHeaders = [
  // headerCheckboxObject(),
  {
    text: "№ п/п",
    tooltip: "Номер по порядку",
    defaultWidth: 100,
    value: fields.TABLE_NUMBER,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Дата поступления",
    tooltip: "Дата утверждения акта приёма-передачи",
    defaultWidth: 160,
    value: fields.TABLE_DATE_RECEIVED,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Номер акта	",
    tooltip: "Номер акта приёма-передачи дел",
    defaultWidth: 200,
    value: fields.TABLE_ACT_NUMBER,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "ОИК",
    tooltip: "Наименование организации, от которой поступили документы",
    defaultWidth: 240,
    value: fields.TABLE_OIK,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Номер описи",
    tooltip: "Опись, в составе которой получен документ",
    defaultWidth: 160,
    value: fields.TABLE_INVENTORY_NUMBER,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Год описи",
    tooltip: "Год описи дел",
    defaultWidth: 100,
    value: fields.TABLE_INVENTORY_YEAR,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Заголовок описи",
    tooltip: "Заголовок документа, по которому приняты документы",
    defaultWidth: 200,
    value: fields.TABLE_INVENTORY_TITLE,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Название фонда",
    tooltip: "Название фонда",
    defaultWidth: 200,
    value: fields.TABLE_FUND_NUMBER,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Начальный/Конечный годы документов",
    tooltip: "Начальный и конечный годы документов",
    defaultWidth: 200,
    value: fields.TABLE_DOC_DURATION,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Количество единиц хранения",
    tooltip: "Количество единиц хранения (ЭАД)",
    defaultWidth: 200,
    value: fields.TABLE_COUNT,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
];
