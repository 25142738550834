import { IReportElement } from "@monorepo/reports/src/views/DocumentsBookReportView/types/reportElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/receiptDocumentsBookReport/fields";

type Keys = keyof typeof fields;
type Values = typeof fields[Keys];

export const convertApiItemToUi = (item: IReportElement): Record<Values, string | number> => ({
  [fields.TABLE_ID]: item.id || "",
  [fields.TABLE_NUMBER]: item.index || "",
  [fields.TABLE_DATE_RECEIVED]: item.registrationDate || "",
  [fields.TABLE_ACT_NUMBER]: item.number || "",
  [fields.TABLE_OIK]: item.oikName || "",
  [fields.TABLE_INVENTORY_NUMBER]: item.inventoryNumber || "",
  [fields.TABLE_INVENTORY_YEAR]: item.inventoryYear || "",
  [fields.TABLE_INVENTORY_TITLE]: item.inventoryTitle || "",
  [fields.TABLE_FUND_NUMBER]: item.fundName || "",
  [fields.TABLE_DOC_DURATION]: item.year || "",
  [fields.TABLE_COUNT]: item.totalFilesCount || "",
});
